@import "styles/colors";
@import "styles/variables";

.streakTable {
  :global(.ant-table-selection-column) {
    visibility: hidden;
  }
  .streakRow {
    cursor: pointer;
  }
}

.btnMapToggle {
  position: absolute;
  left: $spacing-4;
  top: $spacing-3;
  z-index: 1;
}
.emptyResult {
  height: calc(100vh - 435px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .filterIcon {
    font-size: $font-size-64;
    color: $gray-7;
  }
  .filterText {
    color: $gray-7;
  }
}
