@import "styles/variables";

.vehicleGroupTable {
  margin-top: $spacing-4;
  overflow: auto;
  :global(.ant-table-selection-column) {
    visibility: hidden;
  }
}
.vehicleRow {
  cursor: pointer;
}
