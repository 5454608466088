@import "styles/colors";
@import "styles/variables";

body {
  background-color: $bg-gray;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Overwrite styling of Antd SubMenu/MenuItem
#root .ant-menu.ant-menu-horizontal {
  background-color: $brand-primary;
  color: rgba($text-white, 0.8);
  border-bottom: none;

  .ant-menu-item,
  .ant-menu-submenu {
    top: 0;
    margin: 0 #{$spacing-2};
    margin-top: -2px;
  }
  .ant-menu-item {
    padding: 0 #{$spacing-4};

    a {
      color: inherit;
    }
  }
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      color: inherit;
      padding: 0 #{$spacing-4};
    }
  }
  .ant-menu-item-active,
  .ant-menu-item-selected,
  .ant-menu-submenu.ant-menu-submenu-active,
  .ant-menu-submenu.ant-menu-submenu-selected {
    &,
    &:hover {
      color: $text-white;
      border-bottom-color: $brand-secondary;
    }
    &::after {
      border-bottom-color: $brand-secondary;
    }
  }
}

// Sortable table styling
.row-dragging {
  background: $gray-2;
  border: $border-height-1 solid $gray-6;
  .ant-table-cell-fix-right {
    background: transparent;
  }
}

.row-dragging td {
  padding: $spacing-3 $spacing-8;
}

.row-dragging .drag-visible {
  visibility: visible;
}
