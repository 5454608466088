@import "styles/colors";
@import "styles/variables";

$card-spacing: $spacing-4;

.cardContainer {
  width: $card-width;
  border-radius: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 99;
  position: absolute;
  top: $card-spacing;
  left: -$card-width/1.4;

  :global {
    .ant-card-head {
      padding: $spacing-0 $card-spacing;
    }
    .ant-card-head-title {
      font-size: $font-size-14;
      font-weight: $font-weight-400;
    }
    .ant-card-body {
      padding: $card-spacing/2 $card-spacing $card-spacing;
    }
  }
}

.cardAction {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.cardTitle {
  margin: 0 !important;
  font-weight: $font-weight-500;
}
