@import "styles/variables";
@import "styles/colors";

.predefinedMessageWrapper {
  border-bottom: $border-height-1 solid $gray-3;
  padding: $spacing-2 $spacing-6 $spacing-0 $spacing-6;
  max-height: $predefined-message-height;
  overflow: auto;
  .predefinedMsgBtn {
    margin-bottom: $spacing-2;
    margin-right: $spacing-2;
    .ellipsisMsg {
      text-overflow: ellipsis;
      white-space: nowrap;
      width: $predefined-text-width;
      overflow: hidden;
    }
  }
}
.predefinedMessageTooltip {
  :global {
    .ant-tooltip-inner {
      width: $message-tooltip-width-medium;
      white-space: pre-wrap;
    }
  }
}
