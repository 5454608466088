@import "styles/colors";
@import "styles/variables";

.sessionFilterWrapper {
  display: flex;
}

.sessionExportContainer {
  align-self: flex-end;
  margin-left: auto;
}

.sessionFilterForm {
  :global {
    .ant-form-item {
      flex-flow: column;
    }
    .ant-form-item-label {
      text-align: left;
    }
  }

  .multipleSelection {
    min-width: $selection-min-width;
  }
}
