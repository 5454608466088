@import "styles/variables";

.configContent {
  height: 100%;
  width: $map-messenger-config-width;
}

.groupSelectionBox {
  padding: $spacing-6;
  margin-top: $spacing-0;
}
