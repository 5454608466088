@import "styles/variables";
@import "styles/colors";

%stroke {
  stroke: $volcano-6;
}

.mapContainer {
  width: 100%;
  height: calc(100vh - #{$spacing-3} * 2 - #{$navbar-height});
  overflow: hidden;
  position: relative;
}

.settingsContainer {
  position: absolute;
  right: $spacing-4;
  bottom: $spacing-6;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
