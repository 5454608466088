@import "styles/colors";
@import "styles/variables";

$card-width: 385px;
$card-spacing: $spacing-4;

%defaultFontStyle {
  font-weight: $font-weight-800;
  color: $text-black;
}

.vehicleCardContainer {
  width: $card-width;
  border-radius: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 99;
  position: absolute;
  left: -$card-width/2;

  :global {
    .ant-card-head {
      padding: $spacing-0 $card-spacing;
    }
    .ant-card-head-title {
      padding: $card-spacing $spacing-0 $card-spacing/2;
      font-size: $font-size-14;
      font-weight: $font-weight-400;
    }
    .ant-card-body {
      padding: $card-spacing/2 $card-spacing $card-spacing;
    }
  }
}

.cardTitle {
  margin: 0 !important;
}

.currentStatusLabel,
.dateOfLastPositionLabel {
  display: block;
  margin-bottom: $spacing-1;
}

.stopType {
  @extend %defaultFontStyle;
  margin: 0 10px;
}

.lastPositionDate {
  @extend %defaultFontStyle;
}
