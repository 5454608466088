@import "styles/colors";
@import "styles/variables";

@mixin message {
  display: flex;
  width: 100%;
  .messageBubble {
    background: $gray-3;
    color: $text-black;
    padding: $spacing-2 $spacing-4;
    border-radius: $border-radius-16;
    max-width: $bubble-width;
    font-size: $font-size-16;
    .info {
      display: flex;
      justify-content: space-between;
      font-size: $font-size-12;
      .time {
        font-weight: $font-weight-600;
        margin-right: $spacing-1;
        font-size: xx-small;
      }
    }
    .messageBody {
      margin-top: $spacing-2;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}
.status {
  font-size: xx-small;
}
.sentFrom {
  margin-right: 2px;
}
.messageFromDriver {
  @include message;
  .messageBubble {
    border-top-left-radius: $border-radius-2;
  }
}
.messageFromDispatcher {
  @include message;
  flex-direction: row-reverse;
  .messageBubble {
    background: $blue-6;
    border-bottom-right-radius: $border-radius-2;
    color: $text-white;
  }
}
.groupMessageFromDispatcher {
  @include message;
  flex-direction: row-reverse;
  .messageBubble {
    background: $gray-8;
    border-bottom-right-radius: $border-radius-2;
    color: $text-white;
  }
}
