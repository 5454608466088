$text-white: #fff;
$text-black: #000;
$text-black-85: rgba(0, 0, 0, 0.85);

$gray-1: #fff;
$gray-2: #fafafa;
$gray-3: #f5f5f5;
$gray-4: #f0f0f0;
$gray-5: #d9d9d9;
$gray-6: #bfbfbf;
$gray-7: #8c8c8c;
$gray-8: #595959;

$blue-1: #e6f7ff;
$blue-2: #bae7ff;
$blue-3: #91d5ff;
$blue-4: #69c0ff;
$blue-5: #40a9ff;
$blue-6: #1890ff;
$blue-7: #096dd9;
$blue-8: #0050b3;

$geek-blue-5: #597ef7;

$bg-white: #fff;
$bg-gray: #f0f2f5;
$bg-green: #237804;
$bg-blue: #bae7ff;

$brand-primary: #004231;
$brand-secondary: #f7f410;

$green-1: #f6ffed;
$green-7: #389e0d;

$volcano-6: #fa541c;
$volcano-8: #ad2102;
