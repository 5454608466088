@import "styles/variables";
@import "styles/colors";

.predefinedWrapperSmall {
  border-bottom: none;
  padding: $spacing-2;
  max-height: $predefined-message-height-small;
  overflow: auto;
  border: $border-height-1 solid $gray-5;
}

.actionGroupMessage {
  margin-top: $spacing-3;
}
