@import "styles/variables";

.actions {
  &:global(.ant-table-cell-fix-right) {
    z-index: 0;
  }
}

.tableViewContainer {
  :global {
    .ant-table-pagination.ant-pagination {
      margin: $spacing-4;
    }
  }
}
