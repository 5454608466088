@import "styles/colors";
@import "styles/variables";

.messageList {
  :global {
    .ant-list-item {
      flex-direction: column;
      align-items: start;
      border-bottom: none;
      padding-top: $spacing-0;
      padding-bottom: $spacing-2;
    }
  }
}
