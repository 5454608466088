@import "styles/colors";
@import "styles/variables";

.logoContainer {
  display: flex;
  margin: $spacing-3 $spacing-6;

  .logo {
    width: 131px;
    height: 32px;
  }
}
