@import "styles/colors";
@import "styles/variables";

.messageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $bg-white;
  height: $max-content-height;
  .messageHeader {
    border-bottom: $border-height-1 solid $gray-3;
  }
  .messageThread {
    display: flex;
    flex-grow: 1;
    flex-direction: column-reverse;
    overflow: auto;
    padding: $spacing-0 $spacing-6;
    .infiniteScroll {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .messageFooter {
    border-top: $border-height-1 solid $gray-3;
  }
  .loadingMoreMessage {
    display: flex;
    justify-content: center;
    padding: $spacing-3;
  }
}
