@import "styles/colors";
@import "styles/variables";

.navMenu {
  &:global(.ant-menu.ant-menu-horizontal) {
    :global {
      .ant-menu-item-active,
      .ant-menu-item-selected {
        &,
        &:hover {
          border-bottom-color: $text-white !important;
        }
        &::after {
          border-bottom-color: $text-white !important;
        }
      }
    }
  }
}

.badge {
  top: $spacing-2;
  .messageIcon {
    font-size: $font-size-20;
    color: $text-white;
    margin-right: 0;
  }
  :global(.ant-badge-count) {
    background-color: $brand-secondary;
    color: $brand-primary;
    font-weight: $font-weight-700;
    box-shadow: none;
    padding: 0;
  }
}
.avatar {
  background-color: $bg-green;
}

.userMenu {
  .userMenuItem {
    padding: $spacing-1 $spacing-6;
  }
}
