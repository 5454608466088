@import "styles/colors";
@import "styles/variables";

.chassisCheckFilterWrapper {
  display: flex;
}

.chassisCheckExportContainer {
  align-self: flex-end;
  margin-left: auto;
}

.chassisCheckFilterForm {
  :global {
    .ant-form-item {
      flex-flow: column;
    }
    .ant-form-item-label {
      text-align: left;
    }
  }
}

.multipleSelection {
  min-width: $selection-min-width;
}
