@import "styles/colors";
@import "styles/variables";

.messageFilterWrapper {
  display: flex;
}

.messageExportContainer {
  align-self: flex-end;
  margin-left: auto;
}

.messageFilterForm {
  :global {
    .ant-form-item {
      flex-flow: column;
    }
    .ant-form-item-label {
      text-align: left;
    }
  }
}
.dispatcher {
  color: $bg-green;
}
.driver {
  color: $geek-blue-5;
}
