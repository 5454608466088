@import "styles/colors";
@import "styles/variables";

.button {
  border-radius: $border-radius-4;

  &.noPadding {
    padding-left: 0;
    padding-right: 0;
  }
}
