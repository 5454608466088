@import "styles/variables";
@import "styles/colors";

%stroke {
  stroke: $volcano-6;
}

.mapContainer {
  width: 100%;
  height: calc(100vh - #{$spacing-3} * 2 - #{$navbar-height});
  overflow: hidden;
  position: relative;

  // Override measure tool styles
  :global {
    .segment-measure-text,
    .node-measure-text {
      @extend %stroke;
      font-size: $font-size-12;
    }

    .cover-circle,
    .base-line {
      @extend %stroke;
    }

    .cover-circle {
      r: 8;
    }
  }
}

.settingsContainer {
  position: absolute;
  right: $spacing-4;
  bottom: $spacing-6;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.measureGuide {
  position: absolute;
  bottom: 2 * $spacing-6;
  background-color: $bg-white;
  padding: $spacing-2 $spacing-4;
  border-radius: 48px;
  text-align: center;
  font-size: $font-size-14;
  font-weight: $font-weight-400;
}
