@import "styles/colors";
@import "styles/variables";

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid;
  background-color: $volcano-8;
  opacity: 0.3;
}
.toolTip {
  background-color: $text-white;
}
.graphHeading {
  margin-left: 50px;
  display: grid;
  grid-template-columns: auto auto auto;
}
.toolTipSpeed {
  color: $blue-7;
}
.toolTipStatus {
  color: $green-7;
}
