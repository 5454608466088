@import "styles/variables";
@import "styles/colors";

.selectionBoxContainer {
  margin-top: $spacing-8;
  width: 100%;
  .availableItems {
    flex-flow: column;
  }

  .selectedItems {
    flex-flow: column;
  }

  .boxTitle {
    display: flex;
    justify-content: space-between;
    height: $spacing-8;
  }

  .listItems {
    border: $border-height-1 solid $gray-6;
    border-radius: $border-radius-4;
    height: $selection-box-height;
    .checkBoxGroup {
      width: 100%;
    }
    .item {
      text-align: center;
      margin-bottom: $spacing-2;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .scrollable {
    overflow: auto;
  }

  .emptyList {
    display: flex;
    align-items: center;
    .emptyMessage {
      padding: $spacing-3;
      text-align: center;
    }
  }
  .controlButtons {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: $spacing-6;
    .button {
      margin-bottom: $spacing-2;
    }
  }
}
