@import "styles/variables";

.listColumnContainer {
  width: 100%;
  height: calc(100vh - #{$spacing-3} * 2 - #{$navbar-height});
  overflow: hidden;
}

.searchFilter {
  max-width: 420px;
}
