@import "styles/colors";
@import "styles/variables";

.dateDivider {
  width: 100%;
  :global {
    .ant-divider-horizontal.ant-divider-with-text::before,
    .ant-divider-horizontal.ant-divider-with-text::after {
      border: none;
    }
    .ant-divider-plain.ant-divider-with-text {
      font-size: $font-size-16;
    }
  }
}
