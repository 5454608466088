@import "styles/colors";
@import "styles/variables";

@mixin previewContainer {
  display: flex;
  justify-content: center;
  .previewLink {
    padding: $spacing-2;
    word-break: break-all;
    text-decoration: underline;
    color: $text-black;

    &:hover {
      color: $blue-8;
    }
  }
}

.previewFromDriver {
  @include previewContainer;
}
.previewFromDispatcher {
  @include previewContainer;
  .previewLink {
    color: $gray-8;

    &:hover {
      color: $gray-7;
    }
  }
}
.groupPreviewFromDispatcher {
  @include previewContainer;
  .previewLink {
    color: $text-white;
  }
}
