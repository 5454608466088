@import "styles/variables";

$card-spacing: $spacing-3;

.mapSettingsPanelContainer {
  position: absolute;
  right: $spacing-4;
  bottom: $spacing-16 + $spacing-2;
  max-width: 230px;
  border-radius: 4px;
  z-index: 99;

  :global {
    .ant-card-body {
      padding: $card-spacing;
    }
    .ant-checkbox-wrapper {
      margin: 0 !important;
    }
  }
}
