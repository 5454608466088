// Navigation
$navbar-height: 56px;

// Max content height
$max-content-height: calc(100vh - 80px);

// Spacing
$spacing-0: 0;
$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 12px;
$spacing-4: 16px;
$spacing-5: 20px;
$spacing-6: 24px;
$spacing-7: 28px;
$spacing-8: 32px;
$spacing-9: 36px;
$spacing-10: 40px;
$spacing-11: 44px;
$spacing-12: 48px;
$spacing-13: 52px;
$spacing-14: 56px;
$spacing-15: 60px;
$spacing-16: 64px;
$negative-spacing-1: -1px;

// Font sizes
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-32: 32px;
$font-size-40: 40px;
$font-size-48: 48px;
$font-size-64: 64px;

// Font weight
$font-weight-100: 100;
$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;

// Border height
$border-height-1: 1px;

// Border radius
$border-radius-2: 2px;
$border-radius-4: 4px;
$border-radius-16: 16px;

// Message bubble
$bubble-width: 360px;

// Predefined message
$predefined-message-height: 250px;
$predefined-message-height-small: 200px;
$predefined-text-width: 300px;

//Selected items max height
$selected-items-height: 55px;

// Table height inside modal
$modal-table-height: 350px;

// Selection box height
$selection-box-height: 450px;
$selection-box-height-large: 520px;

// Map messenger config width
$map-messenger-config-width: 760px;

//Predefined message tooltip width
$message-tooltip-width-large: 500px;
$message-tooltip-width-medium: 350px;

// Multiple selection min width
$selection-min-width: 160px;
$selection-max-width: 400px;

// Position report map
$card-width: 200px;
$marker-outer-size: 32px;
$marker-inner-size: 24px;
$circle-box-shadow-spread: 3px;
