@import "styles/colors";
@import "styles/variables";

.messageTable {
  .tableHeaderCol {
    padding: $spacing-4;
    overflow-wrap: break-word;
    color: $text-black-85;
    font-weight: 500;
    text-align: left;
    background: $gray-2;
    border-bottom: 1px solid $gray-4;
    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: 1.6em;
      background-color: rgba(0, 0, 0, 0.06);
      transform: translateY(-50%);
      transition: background-color 0.3s;
      content: "";
    }
  }

  .unreadMessage {
    background-color: $brand-primary;
    color: $brand-secondary;

    &:hover {
      color: $green-7;
    }
  }

  .tableRow {
    cursor: pointer;
    margin-bottom: 1px;
    border-bottom: 1px solid $gray-4;
    &:hover {
      background: $gray-2;
    }
  }

  .selectedRow {
    color: $text-black-85;
    background-color: $blue-2;
  }

  .tableCol {
    padding: $spacing-1;
  }

  .received {
    padding-left: 10px;
  }

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
}
