@import "styles/colors";
@import "styles/variables";

$marker-outer-size: 32px;
$marker-inner-size: 12px;
$marker-info-width: 140px;

.marker {
  // using translate to move the marker circle into the middle of the coordinates
  transform: translate(-50%, -#{$marker-outer-size/2});
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.markerContainer {
  height: $marker-outer-size;
  width: $marker-outer-size;
  background: radial-gradient(
    50% 50% at 50% 50%,
    $green-1 0%,
    rgba(246, 255, 237, 0) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;

  .triangleUp {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $green-7;
    position: absolute;
    top: 0;
  }
}

.markerGreenCircle {
  background: $green-7;
  box-shadow: 0 0 0 3px $green-1;
  border-radius: 50%;
  height: $marker-inner-size;
  width: $marker-inner-size;
}

%marker {
  display: inline-block;
  padding: $spacing-0 $spacing-2;
  border: 1px solid $gray-7;
  background: $bg-white;
  overflow: hidden;

  legend {
    font-size: $font-size-12;
    line-height: 18px; // 20px - 2 x 1px border
    margin: 0;
    color: $gray-8;
    text-align: center;
  }
}

.vehicleNo {
  @extend %marker;
  border-radius: 12px;

  legend {
    font-weight: $font-weight-600;
  }
}

.driverName,
.dateTime {
  @extend %marker;
  width: $marker-info-width;
  margin: $spacing-1 $spacing-0 $spacing-0;
}

.markerSelected {
  .vehicleNo {
    background: $green-7;
    border-color: $green-7;
    legend {
      color: $gray-1;
    }
  }
}

.statusContainer {
  margin: $spacing-1 $spacing-0 $spacing-0;
}
