@import "styles/colors";
@import "styles/variables";

.selectedItemsContainer {
  margin-bottom: $spacing-2;
  max-height: $selected-items-height;
  overflow: auto;
  .tag {
    margin-bottom: $spacing-1;
    border-radius: $border-radius-4;
  }
}
.buttonLabel {
  color: $blue-6;
}
