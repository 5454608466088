@import "styles/colors";
@import "styles/variables";

.active {
  color: $bg-green;
  font-weight: $font-weight-500;
}

.deActive {
  font-weight: $font-weight-500;
  color: $volcano-6;
}
