@import "styles/colors";
@import "styles/variables";

.damageItem {
  margin-left: $spacing-4;
  .attachment {
    padding: $spacing-2;
  }
  @mixin icon {
    font-size: $font-size-20;
  }
  .fixedIcon {
    color: $green-7;
    @include icon;
  }
  .notFixedIcon {
    color: $volcano-6;
    @include icon;
  }
  .commentIcon {
    color: $blue-5;
    @include icon;
  }
}

.divider {
  margin: $spacing-1 $spacing-0;
}
