@import "styles/colors";
@import "styles/variables";

.chassisCheckTable {
  .chassisCheckUnread {
    background-color: $blue-1;
    :global {
      .ant-table-cell-fix-left,
      .ant-table-cell-fix-right {
        background: $blue-1;
      }
    }
  }
}

.tooltipNotes {
  :global {
    .ant-tooltip-inner {
      white-space: pre-wrap;
    }
  }
}

.tooltipLargeNotes {
  :global {
    .ant-tooltip-inner {
      white-space: pre-wrap;
      width: $message-tooltip-width-large;
    }
  }
}
