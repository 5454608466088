@import "styles/colors";
@import "styles/variables";

.container {
  margin: $spacing-3;
  min-height: 300px;
}

.content {
  min-height: calc(100vh - #{$spacing-3} * 2 - #{$navbar-height});
}
