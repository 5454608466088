@import "styles/colors";
@import "styles/variables";

.cluster {
  background: $green-7;
  border-radius: 50%;
  box-shadow: 0 0 0 4px $bg-white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  legend {
    font-size: $font-size-18;
    font-weight: $font-weight-600;
    margin: 0;
    color: $text-white;
    text-align: center;
  }
}
