@import "styles/variables";
.radioGroup {
  margin-bottom: $spacing-0;
  :global(.ant-form-item-label) {
    font-weight: $font-weight-500;
  }
}
.radio {
  display: block;
  &:global(.ant-radio-wrapper) {
    white-space: normal;
  }
  .radioDescription {
    display: block;
    font-size: $font-size-12;
    padding-top: $spacing-1;
  }
}
