@import "styles/colors";
@import "styles/variables";

%common-input {
  font-size: $font-size-16;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
}

%shared-input {
  min-width: 0;
  background-color: $bg-white;
  background-image: none;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
}

.placeSearchBoxContainer {
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: row;
  padding: 10px;

  .inputAffixWrapper {
    @extend %shared-input;
    @extend %common-input;
    box-sizing: border-box;
    padding: 6.5px 11px;
    width: 100%;
    border: 1px solid $gray-5;
  }

  .searchInput {
    @extend %shared-input;
    @extend %common-input;
    width: 300px;
    padding: 0;
    border: none;
    outline: none;
    text-overflow: ellipsis;
    margin: 0;
    list-style: none;
    display: inline-block;
  }

  .inputSuffix {
    @extend %common-input;
    cursor: pointer;
    box-sizing: border-box;
    margin-left: 4px;
    display: flex;
    flex: none;
    align-items: center;
  }

  .showSearchBox {
    display: inline-flex;
  }

  .hideSearchBox {
    display: none;
  }

  .visibleClear {
    visibility: visible;
  }

  .hideClear {
    visibility: hidden;
  }
}
