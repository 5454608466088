@import "styles/colors";
@import "styles/variables";

.marker {
  transform: translate(-50%, -#{$marker-outer-size/2});
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.markerContainer {
  cursor: pointer;
  height: $marker-outer-size;
  width: $marker-outer-size;
  display: flex;
  align-items: center;
  justify-content: center;
}

.markerStopCircle {
  background: $geek-blue-5;
  box-shadow: 0 0 0 $circle-box-shadow-spread $green-1;
  border-radius: 50%;
  height: $marker-inner-size;
  width: $marker-inner-size;
}

.markerSelected {
  .markerStopCircle {
    background: $green-7;
    height: $marker-inner-size + 4;
    width: $marker-inner-size + 4;
  }
}
