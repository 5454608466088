@import "styles/colors";
@import "styles/variables";

.container {
  padding: $spacing-6 $spacing-6 $spacing-7;

  .title {
    margin-bottom: $spacing-2;
  }
}

.extras {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-4;

  .filters {
    flex: 1;
  }
}
