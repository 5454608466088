@import "styles/colors";
@import "styles/variables";

.reportDescription {
  :global {
    .ant-descriptions-item-content {
      font-weight: $font-weight-600;
    }
  }
}
