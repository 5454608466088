@import "styles/variables";

.messageTooltip {
  :global {
    .ant-tooltip-inner {
      width: $message-tooltip-width-large;
      white-space: pre-wrap;
    }
  }
}
