@import "styles/variables";
@import "styles/colors";

.actionButtonsWrapper {
  display: flex;
  .predefinedMessageBtn {
    margin-right: $spacing-2;
  }
  .buttonLabel {
    margin-left: $spacing-1;
  }
  .activated {
    color: $blue-5;
    border-color: $blue-5;
  }
}
.dropzone {
  text-align: center;
  border: 1px solid #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  border-radius: 50px;
}

.accept {
  padding: 5px;
  background-color: #bdbdbd;
  border-color: $brand-secondary !important;
}
