@import "styles/colors";
@import "styles/variables";

.messageInputContainer {
  padding: $spacing-2 $spacing-6;
  .messageForm {
    align-items: center;
    flex-grow: 1;
    .messageBody {
      flex-grow: 1;
      margin-right: 0;
      :global {
        .ant-input {
          &:hover,
          &:focus {
            z-index: 1;
          }
        }
      }
    }
    .sendButton {
      margin-left: $negative-spacing-1;
    }
  }
  .actionSingleMessage {
    justify-content: flex-end;
  }
}
