@import "styles/colors";
@import "styles/variables";

.table {
  :global {
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr.ant-table-row-selected > td {
      &:not(.ant-table-selection-column) {
        background: initial;
        border-color: $gray-4;
      }
    }

    .ant-table .ant-table-selection-col {
      width: 64px;
    }
    .ant-table tr td.ant-table-selection-column {
      background: $gray-2;
    }
  }
}
