@import "styles/colors";
@import "styles/variables";

.emptyThreadContent {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .messageIcon {
    color: $gray-6;
    font-size: $font-size-64;
    margin-bottom: $spacing-5;
  }
  .emptyText {
    color: $gray-7;
  }
}
