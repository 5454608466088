@import "styles/variables";

:global {
  .ant-tabs-nav {
    padding: 0 $spacing-6;
  }
  .ant-tabs-tab {
    font-size: $font-size-16;
  }
}

.messageRow {
  height: 100%;
}

.messageListContent {
  margin-right: $spacing-3;
  height: 100%;
}

.filterContainer {
  display: flex;
}

.searchFilter {
  margin-right: $spacing-3;
}

.outGoingScreenTitle {
  padding-bottom: $spacing-4;
}
