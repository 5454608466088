@import "styles/colors";
@import "styles/variables";

@mixin senderName {
  display: flex;
  width: 100%;
  font-size: $font-size-12;
  color: $gray-8;
  margin: $spacing-2 $spacing-0;
}
.driverName {
  @include senderName;
}
.dispatcherName {
  @include senderName;
  flex-direction: row-reverse;
}
