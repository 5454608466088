@import "styles/colors";
@import "styles/variables";

.navbar {
  width: 100%;
  color: $text-white;
  padding: 0;
  &.sticky {
    position: sticky;
    z-index: 2;
    top: 0;
  }

  .navLeftContent {
    display: flex;
  }
  .navRightContent {
    display: flex;
    flex-direction: row-reverse;
  }
}
